var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "container feedback-form"
  }, [_c('div', {
    staticClass: "card"
  }, [_c('h1', {
    staticClass: "tac"
  }, [_vm._v("Feedback")]), _c('p', {
    staticClass: "tac"
  }, [_vm._v("Um die Webapp stetig zu verbessern sind wir auf Feedback, neue Ideen und Kritiken angewiesen, welche Sie uns hier mitteilen können.")]), _c('div', {
    staticClass: "input-field"
  }, [_c('div', {
    staticClass: "input"
  }, [_c('tip-tap', {
    key: _vm.contentKey,
    attrs: {
      "content": this,
      "ckey": "content"
    }
  })], 1)]), _c('div', {
    staticClass: "input-field"
  }, [_c('div', {
    staticClass: "input"
  }, [!_vm.sending ? _c('button', {
    staticClass: "btn _mid _bordered",
    on: {
      "click": function click($event) {
        return _vm.send();
      }
    }
  }, [_c('span', [_vm._v("Senden")])]) : _c('i', {
    staticClass: "loading-spinner"
  })])])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };